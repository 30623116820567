import { memo } from 'react';
import {
  ResourceComponentProps,
  useRecordContext,
  Datagrid,
  TextField,
} from 'react-admin';
import { ReadyToUseDomainDeleteButton } from '../common/buttons/ReadyToUseDomainSenderDeleteButton';

export const ReadyToUseDomainSenderDatagrid: React.FC<ResourceComponentProps> =
  memo((props) => {
    const record = useRecordContext(props);

    return (
      <Datagrid>
        <TextField
          source="smartleadEmailAccountId"
          label="Smartlead Account ID"
        />
        <TextField source="name" label="Sender Name" />
        <TextField source="email" label="Sender Email" />
        {record?.id && (
          <ReadyToUseDomainDeleteButton
            resourceId={record.id as string}
            redirect={false}
          />
        )}
      </Datagrid>
    );
  });
