import {
  EventFeatureConfig,
  OrganizationFeatureConfig,
  PricingPlanEventFeatureConfig,
} from './feature-config';
import { ReleaseStage } from './release-control';

export * from './custom-automated-email-sender-type';
export * from './feature-config';
export * from './release-control';
export * from './feature-config-input';
export * from './public-api-token';
export interface EventListItem {
  id: string;
  name: string;
  startsAt: string;
  endsAt: string;
  createdAt: string;
  organizationId: string;
  createdByUserId?: string;
}

export type EventDetails = EventListItem & {
  featureConfigOverride: EventFeatureConfig;
  resolvedFeatureConfig: EventFeatureConfig;
};

export interface PricingPlanDetails {
  id: string;
  note: string | null;
  contentI18n: any;
  key: string | null;
  organizationFeatureConfig: OrganizationFeatureConfig;
  defaultEventFeatureConfig: Partial<PricingPlanEventFeatureConfig>;
  resolvedEventFeatureConfig: PricingPlanEventFeatureConfig;
  createdAt: string;
}

export interface SubscriptionDetails {
  id: string;
  pricingPlanId: string;
  organizationId: string;
  note: string | null;
  expiryBehavior: string;
  expiresAt: string | null;
  startedAt: string;
  chargeMethod: 'chargebee' | 'manual';
  personInCharge: string | null;
  internalReferenceNo: string | null;
}

export interface OrganizationDetails {
  id: string;
  name: string;
  createdByUserId: string;
  releaseStage: ReleaseStage;
  createdAt: string;
  paymentOverduesAt: string;
  activeSubscriptionId: string;
  tenantDbHost: string;
}

export enum DefaultLandingSystem {
  ESAAS = 'esaas',
  EMS = 'ems',
}
export interface User {
  id: string;
  name: string;
  email: string;
  defaultLandingSystem: DefaultLandingSystem;
  isEmsUser: boolean;
}

export interface CustomDomain {
  defaultRedirectUrl: string;
  domain: string;
  id: string;
  organizationId: string;
}

export interface CustomDomainPathMapping {
  pathName: string;
  customDomainId: string;
  organizationId: string;
  eventId: string;
  updatedAt: string;
  createdAt: string;
  id: string;
}

export interface EventXSystemConfig {
  id: string;
  isMultiTenantEnabled: boolean;
  updatedAt: string;
}

export interface ReadyToUseDomainSender {
  id: string;
  organizationId: string;
  smartleadEmailAccountId: string;
}
