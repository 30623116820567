import { ReadyToUseDomainSender } from '@/types';
import { ComponentType, useCallback } from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';
import { useHistory } from 'react-router-dom';

export const ReadyToUseDomainSenderCreate: ComponentType = (props) => {
  const history = useHistory();

  const onSuceess = useCallback(
    (result: { data: ReadyToUseDomainSender }) => {
      const url = `/organizations/${result.data.organizationId}/show`;
      history.push(url);
    },
    [history],
  );

  return (
    <Create onSuccess={onSuceess} {...props}>
      <SimpleForm>
        <TextInput fullWidth source="organizationId" validate={[required()]} />
        <TextInput
          fullWidth
          source="smartleadEmailAccountId"
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  );
};
