import IconAdd from '@material-ui/icons/Add';
import { CreateButton, ResourceComponentProps } from 'react-admin';

export const ReadyToUseDomainSenderCreateButton = (
  props: ResourceComponentProps & {
    title?: string;
    organizationId?: string;
  },
) => {
  const { resource, title, organizationId, ...rest } = props;
  const fullPath = '/ready-to-use-domain-senders/create';

  return (
    <CreateButton
      to={{
        pathname: fullPath,
        state: { record: { organizationId } },
      }}
      label={title ?? 'Add Sender'}
      title={title ?? 'Add Sender'}
      {...rest}
    >
      <IconAdd />
    </CreateButton>
  );
};
